.cp-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    text-transform: uppercase 
  }

  .text-purple {
    color: #7A2ED3
  }

  .btn {
    border-radius: 200px;
  }
}

.m-r-10 {
  margin-right: 10px;
}


.iconExcel {
  width: 25px;
  margin-left: 5px;
  margin-bottom: 2px;
}