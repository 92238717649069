.groupBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 25px;
      margin-right: 10px;
    }
  }
}

.selectClass {
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;

  .bold {
    font-weight: bold;
  }
}

.selectClasses {

  margin-bottom: 20px;

  input {
    margin-right: 10px;
  }
  label {
    margin: 0;
  }
  .selectBlock {
    display: flex;
    flex-direction: column;
  }
}

.noPlansBlock.validation {
  .noPlansImage {
    width: 465px;
  }

  h3 {
    width: 80%;
    margin: 0 auto 25px;
  }
}