$primary: #7a2ed3;
$primary-darkest: #4a1b80;

.courseLesson {
  text-align: left;
  color: $primary;
  cursor: pointer;

  span {
    display: block;

    i {
      margin-left: 5px;
    }
  }

  // &.vacation,
  // &:hover {
  //   color: #979797!important;
  //   cursor: inherit;
  // }

  &.vacation,
  &.holiday {
    cursor: default;
  }

  &:hover {
    color: $primary-darkest;
  }

  .courseLink {
    cursor: pointer;

    &.blocked {
      cursor: not-allowed;
    }
  }

  &.blocked {
    cursor: not-allowed;

    .courseLink {
      cursor: inherit;

      &:hover {
        color: $primary !important;
      }
    }

    &:hover {
      color: $primary !important;
    }
  }

  .lessonLabel {
    color: #979797;
    font-size: 12px;
    margin-left: 5px;

    &.holiday {
      border-top: 1px solid #bbb;
      padding-top: 5px;
      margin-top: 5px;
    }
  }
}
