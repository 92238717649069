
/* Absolute Center Spinner */
  .loading:not(.simple) {
    display: none;
    position: fixed;
    z-index: 9999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* Transparent Overlay */
    /* :not(:required) hides these rules from IE9 and below */
  }
  .loading.simple {
    z-index: 9999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: 0 auto;
    /* Transparent Overlay */
    /* :not(:required) hides these rules from IE9 and below */
  }
  .loading.show {
    display: block;
  }
  .loading:not(.simple):before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .loading.message:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .loading.simple:before {
    content: "";
    display: block;
    height: 10px;
    width: 5px;
//    background-color: rgba(0, 0, 0, 0.3);
  }
  .loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  .loading:not(.simple):not(:required):after {
    content: "";
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(255, 255, 255, 0.8) 1.5em 0 0 0, rgba(255, 255, 255, 0.8) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.8) 0 1.5em 0 0, rgba(255, 255, 255, 0.8) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.8) -1.5em 0 0 0, rgba(255, 255, 255, 0.8) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.8) 0 -1.5em 0 0, rgba(255, 255, 255, 0.8) 1.1em -1.1em 0 0;
    box-shadow: rgba(255, 255, 255, 0.8) 1.5em 0 0 0, rgba(255, 255, 255, 0.8) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.8) 0 1.5em 0 0, rgba(255, 255, 255, 0.8) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.8) -1.5em 0 0 0, rgba(255, 255, 255, 0.8) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.8) 0 -1.5em 0 0, rgba(255, 255, 255, 0.8) 1.1em -1.1em 0 0;
  }
  .loading.simple:not(:required):after {
    content: "";
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.3) 1.5em 0 0 0, rgba(0, 0, 0, 0.3) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.3) 0 1.5em 0 0, rgba(0, 0, 0, 0.3) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.3) -1.5em 0 0 0, rgba(0, 0, 0, 0.3) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.3) 0 -1.5em 0 0, rgba(0, 0, 0, 0.3) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.3) 1.5em 0 0 0, rgba(0, 0, 0, 0.3) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.3) 0 1.5em 0 0, rgba(0, 0, 0, 0.3) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.3) -1.5em 0 0 0, rgba(0, 0, 0, 0.3) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.3) 0 -1.5em 0 0, rgba(0, 0, 0, 0.3) 1.1em -1.1em 0 0;
  }
  
  /* Animation */
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .loading-text {
    color: white;
    z-index: 99999;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }