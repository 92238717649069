.steps {
    color: $gray;
    text-align: center;
    font-size: 12px;

    .step {
        position: relative;
        z-index: 1;

        .circle {
            background-color: #fff;
            border-radius: 50%;
            border: 2px solid $gray;
            font-size: 15px;
            font-weight: bold;
            height: 25px;
            line-height: 1.4em;
            margin: 5px auto;
            width: 25px;
        }
    
        .text {
            font-weight: bold;
        }
    
        &:after {
            background-color: $gray;
            content: '';
            height: 2px;
            left: 0;
            position: absolute;
            right: 0;
            top: 34px;
            z-index: -1;
        }

        &:first-child:after {
            left: 50%;
        }

        &:last-child:after {
            right: 50%;
        }

        &.done {
            color: $green-ocean;

            .circle {
                border-color: $green-ocean;
            }
        }

        &.active {
            color: $orange;

            .circle {
                border-color: $orange;
            }
        }
    }
}
