@import '../scss/main';

.form-control-label {
  font-family: "Lato", sans-serif;
  color: #8D8D8D;
  margin-bottom: 0;
  font-size: 14px;
  &.is-invalid {
    color: $form-feedback-invalid-color;
  }
  &.is-valid {
    color: $form-feedback-valid-color;
  }
}

.valid-feedback,.invalid-feedback{
  position: absolute;
}

.toggle-password-wrapper {
  position: relative;

  .toggle-password {
      background-color: inherit;
      position: absolute;
      color: $gray-dark;
      cursor: pointer;
      padding: 15px;
      border: none;
      bottom: 0;
      right: 0;
      top: 0;
  }

  .form-control {
      padding-right: 45px;
  }
}