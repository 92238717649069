.btn-back, .btn-showMore  {
  color: #9A9A9A;
  background-color: transparent;
  border: none;
  text-transform: lowercase;
  margin-right: 15px;

  &:hover {
    color: #4f4f4f;
  }
}

.myLessons-container {
  border-bottom: 1px dashed #000;
  padding-bottom: 35.5px;
  margin-bottom: 20px;

  i {
    font-size: 40px;
    margin-right: 15px;
  }
}

.classes-found {
  font-size: 20px;
}

button.btn-classDate {
  width: 100%;
  min-width: 100px;
  font-size: 15px;
  font-weight: bold;
  background-color: #7A2ED3;
    color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
  border: none;
}

.class-title {
  font-size: 15px;
  font-weight: bold;
  margin-left: 15px;
  white-space: nowrap;
}

.showMore {
  display: none;
  &.true {
    display: block;
  }
}

.btn-showMore  {
  text-decoration: underline;
  padding: 0;
  margin: 0;
  color: #7A2ED3;
}

button.btn-loadMore {
  width: 100%;
  min-width: 100px;
  font-size: 15px;
  font-weight: bold;
  background-color: #F7F7F7;
  color: #9A9A9A;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
  border: none;

  &.active, &:hover {
    background-color: #7A2ED3;
    color: #fff;
  }
}
.icons-block {
  gap: 5px;
}
.icon-item {
  background-color: #7A2ED3;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .status {
    color: #7A2ED3;
    background-color: #fff;
    border: 1px solid #7A2ED3;
    border-radius: 100%;
    position: absolute;
    top: -5px;
    left: 28px;
    width: 15px;
    height: 15px;
    z-index: 2;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg, svg path {
    fill: #fff;
    width: 20px;
    height: 20px;
  }
}

