// Colors
$primary: #7a2ed3;
// $primary-light: #8c70ac;
$primary-light: #B59BF6;
$primary-lightest: #cee3f4;
// $primary-light: #88b2d6;
$primary-dark: #7a2ed3;
$primary-darkest: #4a1b80;


// Orange
$orange: #f78e00;
$orange-dark: #e87d00;
$orange-light: #ffb900;
$orange-medium: #fb9f48;
$orange-lighter: #f5d1c9;

// Gray
$gray-lighter: #f5f5f5;
$gray-light: #f5f6f7;
$gray-lighty: #ededed;
$gray: #cccccc;
$gray-medium: #b5b5b5;
$gray-dark: #9a9a9a;

// White
$white: #ffffff;
$white-ice: #f7f7f7;

// Green
$green: #108e31;
$green-light: #66ddab;
$green-ocean: #2cdba5;

// Red
$red: #eb472b;
$red-dark: #d60d0d;

// Purple
$purple: #8c70ac;

// Fonts
$font-primary: 'Lato', sans-serif;
$font-secondary: 'Exo 2', sans-serif;

// shadows
$shadow-default: 0 0 15px 0 rgba(black, 0.1), 1px 1px 2px 0 rgba(black, 0.075);
$shadow-hover: 0 0 20px 0 rgba(black, 0.2), 1px 1px 5px 0 rgba(black, 0.05);
