.sticky-top {
  top: 100px;
}

.card {
  @include transition;
  box-shadow: $shadow-default;
  border: none;
  margin-bottom: 30px;

  &:hover {
    box-shadow: $shadow-hover;
  }

  p {
    color: #b5b5b5;
    font-size: 13px;
  }

  .card-body {
    i.icon {
      font-size: 60px;
      color: #7a2ed3;
    }
  }

  .card-id {
    float: left;
    font-size: 12px;
    color: #7a2ed3;
    position: absolute;
  }

  .edit-card {
    font-size: 9px;
    text-align: center;
    color: $gray-medium;
    background: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: -35px;

    &-variation {
      position: relative;
      top: 0;

      &-profile {
        font-size: 14px;
        padding: 0;

        i {
          font-size: 30px !important;
        }
      }
    }

    i {
      display: block;
      font-size: 18px;
    }
  }

  .card-disabled {
    i {
      color: #7a2ed3;
    }

    p,
    .card-date {
      color: #dbdbdb !important;

      i {
        color: #dbdbdb;
      }
    }

    .btn.btn-sm {
      background-color: #dbdbdb;

      &:hover.btn-warning {
        background-color: #a9a5a5 !important;
      }
    }
  }

  .card-completed {
    i {
      color: #ccf7ea;
    }

    .card-date {
      color: #0dd698 !important;

      i {
        color: #0dd698;
      }
    }

    .btn.btn-sm {
      background-color: #0dd698;

      &:hover.btn-warning {
        background-color: #09ad7a !important;
      }
    }
  }

  .card-problem,
  .card-unity {
    .card-alert {
      color: #7a2ed3;
      font-weight: bold;

      span {
        background-color: #f59f8d;
        font-size: 12px;
        color: #fff;
        border-radius: 50%;
        padding: 1px 5px;
      }
    }
  }

  .card-unity-audit {
    p {
      color: #7a2ed3;
    }

    .audit-color {
      color: #ef8100;
    }
  }

  &.card-highlight {
    text-align: center;

    .highlight-title {
      text-align: left;
      line-height: 1em;
      font-weight: bold;
      color: #867a65;

      span {
        font-weight: normal;
        display: block;
      }
    }

    .highlight-number {
      font-size: 60px;
      font-weight: bold;
      color: #919191;
      margin: 1.25rem 0;
    }
  }

  .cm-icon {
    font-size: 40px;
    color: $orange-lighter;
    line-height: 1em;

    @include media-breakpoint-down(lg) {
      font-size: 50px;
      display: inline-block;
    }
  }

  .card-icon {
    background: #e4e4e4;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    color: #ffffff;
    font-size: 45px;
    line-height: 70px;
    text-align: center;
  }

  .card-title {
    color: $primary-dark;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: bold;
    margin: 0;

    i {
      margin-right: 10px;
    }

    .card-open {
      font-weight: bold;
      padding-top: 4px;
      float: right;
    }

    &-big {
      font-size: 24px;
      text-transform: uppercase;
      margin-top: 20px;
      margin-bottom: 25px;
      font-family: $font-secondary;
      color: #ff836a;
    }
  }

  .card-title-alt {
    color: $primary;
    text-align: left;
    font-weight: bold;
    font-size: 18px;

    i {
      display: inline;
    }
  }

  .card-bottom-text {
    line-height: 1rem;
    margin-bottom: 0;
  }

  .card-more {
    border-top: 1px solid $gray-light;
    text-decoration: none;
    display: block;
    color: $gray-dark;
    font-size: 14px;
    margin: 20px -10px -20px;
    padding: 10px;
  }

  .fixed-actions {
    position: absolute;
    text-align: center;
    line-height: 1rem;
    padding: 10px;
    right: 10px;
    top: 10px;

    &.active {
      .cm-floating {
        transform: scale(1);

        &.floating-top {
          transform: translateX(-50%) scale(1);
        }
      }
    }

    i.cm-icon {
      font-size: 1.2rem;
      display: block;
    }

    span.cm-text {
      white-space: nowrap;
      text-decoration: none;
      display: inline-block;
      font-size: 0.7rem;
      color: $gray;
    }

    button.cm-button {
      -webkit-appearance: none;
      background: transparent;
      border-bottom: none;
      border-left: none;
      border-right: none;
      border-top: 1px solid #e1e0e0;
      color: #8e8e8e;
      display: block;
      font-weight: bold;
      padding: 15px 0;
      width: 100%;
    }
  }

  .cm-floating {
    @include transition;
    background-color: $white-ice;
    border-radius: 3px;
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
    font-size: 14px;
    position: absolute;
    right: 200%;
    text-align: left;
    top: -50%;
    transform-origin: 110% 30px;
    transform: scale(0);
    white-space: normal !important;
    will-change: filter;
    z-index: 1;

    &:after {
      content: '';
      width: 30px;
      height: 30px;
      background-color: $white-ice;
      position: absolute;
      right: -3px;
      top: 15px;
      transform: rotate(45deg);
      z-index: -1;
    }

    &.floating-top {
      bottom: 150%;
      left: 50%;
      top: auto;
      width: 250px;

      &:after {
        bottom: 0;
        left: auto;
        right: 50%;
        top: auto;
        transform: rotate(45deg) translateX(50%);
      }
    }

    .cm-title {
      font-size: 18px;
      color: $gray-dark;
      margin-bottom: 20px;

      i {
        margin-right: 10px;
        transform: translateY(1px);
        display: inline-block;
      }
    }

    .accordion-title {
      display: block;
      color: $gray-dark;
      text-decoration: none;
      padding: 10px 0;
      font-size: 14px;

      &:hover {
        color: $primary;
      }

      i {
        display: block;
        font-size: 20px;
        float: right;
        color: #e4e4e4;
      }
    }
  }

  a.icon-link {
    display: block;
    font-size: 10px;
    color: $gray-medium;
    text-decoration: none;
    padding: 6px 8px;
    text-align: center;

    &:hover {
      color: $primary;
    }

    i {
      display: block;
      font-size: 20px;
    }
  }

  .featured-number {
    font-size: 2.6vw;
    font-weight: bold;
    margin-bottom: 0;
  }
}

// card school
.card-school {
  text-align: center;

  .card-body {
    padding-bottom: 40px;
  }

  .card-icon {
    margin: 10px 0 20px;
  }

  .card-description {
    margin-bottom: 20px;
  }

  .cod {
    margin-bottom: 5px;
  }

  .btn {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    max-width: calc(100% - 2.5rem);

    &.btn-sm {
      font-size: 13px;
      padding: 1px 10px;
    }
  }

  .btn.btn-warning {
    text-transform: none;
    background-color: #7a2ed3;
  }

  .btn.btn-danger {
    text-transform: none;
    background-color: #7a2ed3;
  }

  .btn.btn-success {
    text-transform: none;
    background-color: #0dd698;
  }

  .card-bottom-text {
    line-height: 1.25em;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 13px;
  }
}

.card-professors {
  margin-bottom: 20px;

  .card-body {
    padding: 0.75rem 1.25rem;
  }

  .card-icon {
    width: 50px;
    height: 50px;
    font-size: 40px;
    line-height: 50px;
  }

  .card-description {
    margin-bottom: 0;
  }
}

// Card Menu
.card-menu {
  text-align: center;
  position: absolute;
  color: $gray-light;
  font-size: 16px;
  right: 0px;
  top: 5px;
  width: 30px;
  height: 30px;
  padding-top: 4px;
  z-index: 10;
  cursor: pointer;

  &.active,
  &:hover {
    color: $primary;
  }
}

// Card Adicionar Avaliação
.card.add-new {
  background-color: $white-ice;
  text-align: center;
  box-shadow: none;
  border: 1px solid #e1e0e0;
  display: flex;
  flex-flow: row;
  align-items: center;
  text-decoration: none;

  &-profile {
    background-color: transparent;
    border: none;

    &:hover {
      background-color: transparent !important;
      border: none !important;
    }
  }

  span {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    padding: 22px 0;
    color: $gray-dark;
    text-decoration: none;

    @include media-breakpoint-down(lg) {
      color: $primary;
    }
  }

  &:hover {
    box-shadow: none;
    border: 1px solid $gray;
    background-color: lighten($white-ice, 1%);
  }
}

.card-list {
  a {
    padding: 45px 20px;

    .add-icon {
      position: absolute;
      margin: 0;
      top: 50%;
      transform: translateY(-50%);
      left: 25px;
    }
  }
}

// Card Agendamento
.card-materia {
  .card-body {
    @include media-breakpoint-down(lg) {
      padding: 1rem;
    }

    .btn-outline-warning {
      margin-top: 30px;
      font-size: 13px;
      padding: 2px 20px;
    }
  }
}

// Card Avaliação
.card-question {
  padding: 25px;

  .badge {
    width: 50px;
    padding: 13px;
    font-size: 13px;
  }

  .subtitle-question {
    font-weight: bold;
    font-size: 20px;
    font-family: $font-primary;
    position: absolute;
    margin: -33px 65px;
  }

  .question {
    margin-top: 30px;

    p {
      font-size: 17px;
      color: #9b9b9b;
      max-width: 80%;
    }
  }

  .custom-control-input:checked + label {
    color: $primary-light;
  }

  .custom-control-input + label {
    span {
      margin-right: 10px;
    }
  }
}

// Card Time
.card-time {
  padding: 20px;

  .info-time {
    text-align: center;
    color: $primary;
    font-size: 15px;
    font-weight: bold;

    i {
      margin-right: 10px;
    }
  }

  .remaining {
    margin-top: 20px;
    background-color: $white-ice;
    text-align: center;
    border-radius: 5px;

    p {
      color: $gray-dark;
      font-size: 13px;
      margin-bottom: -10px;
      padding-top: 5px;
    }

    span {
      font-size: 30px;
      color: $primary;
      font-weight: bold;
    }
  }

  .btn {
    margin-top: 50px;
    padding: 10px;
  }
}

// Card Perguntas adicionadas
.card-adicionadas {
  padding: 15px;

  @include media-breakpoint-down(md) {
    display: none;
  }

  .questions-nav {
    margin-bottom: 10px;
    margin-top: 20px;

    a {
      color: #d1d1d1;
      text-decoration: none;
      display: inline-block;
      padding: 5px;

      &:hover {
        color: $orange;
      }
    }
  }

  .card-info {
    text-align: right;
    color: darkgray;

    p {
      margin: 0;
    }

    .card-info-number {
      color: $primary-dark;
      font-weight: bold;
      margin-top: -10px;
      font-size: 28px;
    }
  }

  .card-nav {
    margin: 15px 0;
  }

  .card-questions {
    display: inline-block;

    .question-badges {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 7px;
    }

    .question-badge {
      background-color: #dbfff4;
      border-radius: 5px;
      border: 1px solid #2cdba5;
      color: #2cdba5;
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      height: 45px;
      line-height: 1rem;
      padding-top: 13px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      width: 100%;

      &.aprovado {
        background-color: #2cdba5;
        color: #fff;
      }

      &.reprovado {
        background-color: #eb3f00;
        border-color: #eb3f00;
        color: #fff;
      }

      a {
        transition: transform 100ms ease;
        background-color: $primary;
        border-radius: 10px;
        transform: scale(0);
        position: absolute;
        color: #ffffff;
        font-size: 18px;
        right: -6px;
        z-index: 1;
        top: -8px;
      }

      &:hover {
        a {
          transform: scale(1);
        }
      }
    }
  }

  .col-sm-6:first-child {
    padding-left: 15px;
    padding-right: 5px;
  }

  .col-sm-6:last-child {
    padding-right: 15px;
    padding-left: 5px;
  }
}

.card-adicionadas-mob {
  display: none;

  @include media-breakpoint-down(md) {
    margin-top: 80px;
    padding: 5px 15px;
    background-color: $primary-dark;
    display: block;
    border-radius: 0;

    .card-title {
      font-size: 16px;
      color: $white;
      font-weight: normal;
      display: inline-block;

      i {
        margin-right: 10px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .card-title {
      font-size: 14px;
    }
  }
}

.adicionadas-content-mob {
  position: absolute;
  top: 110px;
  left: 0;
  right: 0;
  z-index: 2;
  margin: 0 15px;
  padding: 30px;
  text-align: center;

  .btn {
    @include media-breakpoint-down(xs) {
      font-size: 13px;
    }
  }
}

// Prévia
.card-previa {
  padding: 20px;
}

.card-relatorio-aluno {
  padding-right: 30px;

  .card-body {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .bar-chart {
    margin-top: 35px;
  }
}

.card-unity {
  border-bottom: 1px solid #e1e0e0;

  &:last-child {
    border-bottom: none;
  }

  p {
    font-family: $font-primary;
    font-size: 19px;
    color: #9a9a9a;
    text-align: left;
  }

  span.btn.btn-sm {
    bottom: 50%;
  }

  .edit-card {
    position: relative;
    bottom: 0;

    &-search {
      color: #ef8100;

      i {
        margin-bottom: 5px;
      }
    }

    &-reload {
      color: #efb800;

      &-audit {
        color: #ef8100;
      }

      i {
        margin-bottom: 5px;
      }
    }

    &-completed {
      color: #0dd698;

      i {
        margin-bottom: 5px;
      }
    }
  }
}

.card-footer-skill {
  background-color: transparent;

  .btn {
    color: #c1c1c1;
    font-weight: bold;
    font-size: 16px;
  }
}

.card-date {
  color: #ef8100 !important;
}

.notification {
  position: absolute;
  top: 16%;
  right: 41%;
  background-color: #ef8100;
  color: #fff;
  font-size: 10px;
  padding: 4px;
  border-radius: 50%;
}

.add-icon {
  color: $white;
  background-color: $primary;
  width: 50px;
  height: 50px;
  margin: 0 auto 20px auto;
  padding: 13px 0;
  border-radius: 30px;
  font-size: 25px;

  @include media-breakpoint-down(lg) {
    background-color: transparent;
    border: 1px solid $primary;
    color: $primary;
  }
}

.card-text {
  font-size: 14px !important;
  color: #d0d0d0 !important;
}
