.panel-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px dashed #000;
  padding-bottom: 38.5px;
  margin-bottom: 20px;
}
.panel-container select {
  width: 20vw;
}
.dashboard-card {
  font-size: 1.09vw;
  line-height: 1.61vw;
}
.dashboard-card .card {
  background-color: #F0F0F0;
  color: #979797;
  text-transform: uppercase;
  text-align: center;

  &.purple {
    background-color: #7A2ED3!important;
    color: #fff!important;
    height: 80%;

    .card-body {
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
}
.dashboard-card .number {
  font-size: 2rem;
  line-height: 120%;
}
.dashboard-card .label-text {
  font-size: 1.2rem;
  line-height: 120%;
}

.dashboard-card .card.purple .label-text {
    font-size: 1rem;
}
.calendar-container .react-calendar {
  margin: 0 auto 20px;
}

.noPlansBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;

  .noPlansImage {
    width: 335px;
    margin-bottom: 30px;
  }

  h3 {
    margin: 0 0 10px;
    font-size: 18px;
    font-family: Lato;
  }

  p {
    font-size: 15px;
    margin: 0 auto 30px;
    max-width: 400px;
    text-align: center;
  }

}

.createPlanContainer {
  margin: 0 15px;
  width: 100%;

  h3 {
    margin-bottom: 20px;
  }

  .cards {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    i {
      font-size: 50px;
      margin: 10px;
    }

    .card {
      width: 200px;
      color: #757575!important;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: #fff!important;
      }
    }
  }
}


.calendar-navButton {
  font-size: 35px;
  background: transparent;
  border: none;
  color: #979797;
  text-align: center;
  width: 100%;
  line-height:0;
}

.available-classes {
  margin: 0 15px;
  width: 100%;

  .disciplines-progress {
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    .discipline-progress {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      width: 100%;
      margin: 5px 0;
      padding: 8px;
      border: 2px solid #7a2ed3;

      .discipline {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .icon {
          font-size: 36px;
        }

        .discipline-name {
          margin-left: 10px;
          text-transform: uppercase;
        }

      }

      .progress-percent {
        width: 80px;
        height: 48px;
        padding: 10px 8px;
        font-size: 18px;
        background-color: #7a2ed3;
        color: #fff;
        text-align: center;
        position: relative;
      }

    }
  }
}

.collection-name {
  font-size: 10px;
}


.coverage-container {
  margin: 50px 0 100px;

  span {
    font-size: 12px;
  }

  .students-block {
    margin-bottom: 25px;
  }

  h4 {
    margin-bottom: 30px;
  }

  .student-image {
    .avatar-sm {
      width: 50px!important;
      height: 50px!important;
    }
  }

}

.btn.loadmore {
  background: transparent;
  color: gray!important;
  width: 100%;
  border-top: 1px solid gray;
  margin-top: 46px;

  &:hover {
    color: #7A2ED3!important;
    background-color: transparent!important;
  }
}

.btn-date {
  text-decoration: underline;
  color: #7A2ED3!important;
  cursor: pointer;
}

.heatmap-container {
  display: flex;
  gap:10px;
  flex-wrap: wrap;

  .heatmap-item {
    background-color: #7A2ED3;
    padding: 15px;
    font-size: 12px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    height: auto;
    text-align: center;
  }

  .lessonsTooltip {
    width: calc(20% - 10px);
    height: auto;
    min-width: 18%;
  }

  .text {
    filter: brightness(.3);
  }
}