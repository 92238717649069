.btn {
  font-family: $font-primary;
  padding: 1rem 0.75rem;
  min-width: 200px;
  font-size: 15px;
  text-transform: uppercase;
  box-shadow: none!important;

  @include media-breakpoint-down(sm) {
    padding: 0.5rem 0.75rem;
    min-width: auto;
  }

  &:not([class*="btn-outline-"]) {
    border: none;
  }

  &.btn-primary {
    background-color: #7a2ed3;
  }

  // default states
  &.btn-warning {
    background-color: #7a2ed3;
    color: #fff;
  }

  // default states
  &.btn-warning {
    border-color: #7a2ed3;
    color: white;
  }

  &.btn-primary {
    background-color: #7a2ed3;
    color: #fff !important;

    &-rose {
      background-color: #ff836a;
    }
  }

  &.btn-light{
    &:hover {
      background-color: #9a9a9a !important;
      color: white !important;
    }
    background-color: #F0F0F0;
    color: #9a9a9a !important;
  }

  &.btn-dark-gray,  &.btn-dark-gray.disabled {
    background-color: #7a2ed3;
  }

  // hover and active states
  &:hover,
  &.active,
  &:active {
    &.btn-warning {
      background-color: darken(#7a2ed3, 20%) ;
      color: #fff !important;
    }

    &.btn-primary {
      background-color: darken(#7a2ed3, 20%);
    }
  }

 

  // disabled
  &.disabled {
    background-color: #7a2ed3;
  }

  &.btn-sm,
  &.btn-group-sm > .btn {
    border-radius: 7px;
    padding:0.1rem 1.5rem;
    min-width: auto;
  }

  &.btn-outline-warning {
    border-color:#7a2ed3;
    color:#7a2ed3;

    &:hover,
    &:active {
      background-color: darken(#7a2ed3, 20%)!important;
      color: #fff !important;
      border-color: darken(#7a2ed3, 20%);
    }
  }

  &.btn-outline-primary {
    border-color: #7a2ed3;
    color: #7a2ed3;

    &:hover,
    &:active {
      background-color: darken(#7a2ed3, 20%)!important;
      color: #fff !important;
      border-color: darken(#7a2ed3, 20%);
    }
  }

  &.btn-success {
    background-color: $green-ocean;
  }

  &.btn-outline-success {
    border-color: $green-ocean;
    color: $green-ocean;

    &:hover,
    &:active {
      background-color: darken(#7a2ed3, 20%)!important;
      color: #fff !important;
      border-color: darken(#7a2ed3, 20%);
    }
  }
}

// Adicionar Mais
a.add-more {
  text-decoration: none !important;
  color: $orange;
  margin: auto;

  @include media-breakpoint-down(sm) {
    background-color: $white-ice;
    text-align: center;
    box-shadow: none;
    border: 1px solid $gray-light;
    display: block;
    flex-flow: row;
    align-items: center;
    text-decoration: none;
    padding: 30px 60px;
  }

  &:hover,
  &:active {
    color: $orange;
  }

  i.ti-plus {
    background-color: #ff836a;
    border-radius: 30px;
    margin-right: 10px;
    color: #ffffff;
    padding: 10px;

    @include media-breakpoint-down(sm) {
      display: block;
      margin: auto;
      margin-bottom: 10px;
      max-width: 36px;
      background-color: transparent;
      border: 1px solid $orange;
      color: $orange;
      font-size: 25px;
      padding: 5px;
    }
  }
}

.return {
  font-size: 12px;
  color: #f59f8d;
  font-weight: bold;
  text-decoration: none !important;

  &:hover {
    color: $orange;
  }
}

// Mobile
.btn-mob {
  @include media-breakpoint-down(sm) {
    margin: 20px 0 50px 0;

    a {
      width: 100%;
    }
  }
}

.btn-lg-mob {
  display: none;

  @include media-breakpoint-down(sm) {
    display: inline-block;
    margin-top: 20px;
  }
}

.btn-width-mob {
  @include media-breakpoint-down(sm) {
    width: 54%;
  }
  @include media-breakpoint-down(xs) {
    width: 40%;
  }
}

.btn-modal-mob {
  @include media-breakpoint-down(sm) {
    font-size: 15px;

    span {
      display: none;
    }
  }
}

.btn-sort {
  @include media-breakpoint-down(sm) {
    padding: 10px 20px !important;
  }
}

.btn-card-mob {
  @include media-breakpoint-down(lg) {
    padding: 0 0.9rem !important;
    max-width: 100%;
    font-size: 10px !important;
  }
}

.btn-align {
  position: relative !important;
  bottom: 0 !important;
  left: 0 !important;
  transform: translateX(0) !important;
}




.btn-primary:not(:disabled):not(.disabled):active, 
.btn-primary:not(:disabled):not(.disabled).active, 
.show > .btn-primary.dropdown-toggle {
  background-color: #7a2ed3;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  background-color: #7a2ed3;
  border-color: #7a2ed3;
}