* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media-breakpoint-down(sm) {
    user-select: none;
  }
}

.force-selection {
  user-select: all !important;
}

.text-warning {
  color: $orange-dark !important;
}

body {
  font-family: $font-primary;
  overflow-x: hidden;
  color: #8d8d8d;
  font-size: 16px;
  height: 100vh;

  // rules
  &:before,
  &:after {
    pointer-events: none;
    background: blue;
    position: fixed;
    z-index: 999999;
    // content: '';
  }

  // vertical rule
  &:before {
    height: 100%;
    left: 60px;
    width: 1px;
  }

  // horizontal rule
  &:after {
    width: 100%;
    height: 1px;
    top: 130px;
  }
}

.content-wrapper {
  background-color: #fdfdfd;
  padding: 100px 45px 110px;
  position: relative;

  @include media-breakpoint-down(md) {
    padding: 150px 55px 30px;
  }

  &--expanded {
    padding-left: 150px;

    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }

  .margin-bottom {
    margin-bottom: 50px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }
  }
}

.loadmore,
.load-more {
  text-align: center;
  color: $gray-dark;
  font-size: 15px;
  display: block;
  padding: 5px 0;

  &:hover {
    text-decoration: none;
    color: $orange;
  }
}

.link-info {
  cursor: pointer;
  font-size: 14px;

  i {
    transform: translateY(3px);
    font-size: 1.5em;
  }
}

.tooltip {
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
  opacity: 1 !important;
  width: 250px;

  .tooltip-inner {
    background-color: #f8f8f8;
    transform: translateY(-25%);
    padding: 15px 20px;
    text-align: left;
    color: #9a9a9a;
    max-width: 240px;
    opacity: 1;

    .tooltip-heading {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 16px;
      color: $orange;
    }

    .tooltip-item {
      margin-bottom: 10px;
      font-size: 12px;
    }
  }

  .arrow {
    &:before {
      border-left-color: #f8f8f8;
    }
  }
}

.align-center {
  display: inline-block;

  @include media-breakpoint-down(sm) {
    display: block;
    text-align: center;
    color: $gray-dark;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.display-none {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.display-block {
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.margin-mob {
  @include media-breakpoint-down(md) {
    margin: 0 !important;
  }
}

.margin-top {
  @include media-breakpoint-down(lg) {
    margin-top: 30px !important;
  }
}

.mr-mob {
  @include media-breakpoint-down(sm) {
    margin-right: 15px !important;
  }
}

.border-none {
  @include media-breakpoint-down(sm) {
    border: none !important;
  }
}

.pr-mob {
  @include media-breakpoint-down(md) {
    padding-right: 0;
  }
}

.p-mob {
  @include media-breakpoint-down(xs) {
    padding: 0;
  }
}

.big {
  font-size: 18px;
}

.cod {
  color: $green-ocean;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: bold;

  i {
    margin-left: 5px;
  }
}

.text-success {
  color: $green-ocean !important;
}

a.text-success:focus,
a.text-success:active,
a.text-success:hover {
  color: $green-ocean !important;
}

.dias-letivos {
  text-align: center;
  color: $primary-dark;
  margin-top: 20px;

  .number {
    font-size: 35px;
    font-weight: bold;
    line-height: 1em;
  }
}

.action-bar {
  align-items: flex-start;
  flex-direction: row;
  text-align: right;
  display: flex;

  @include media-breakpoint-down(sm) {
    background-color: $white-ice;
    margin-top: 20px;
  }

  .action-bar-item {
    display: inline-block;
    text-decoration: none;
    vertical-align: top;
    text-align: center;
    margin-left: 20px;
    color: $gray-dark;
    font-size: 13px;

    @include media-breakpoint-down(lg) {
      margin: auto;
      padding: 10px;
      font-size: 11px;
    }

    span {
      display: block;
    }

    i {
      font-size: 35px;
      display: block;
    }

    &:hover {
      color: $primary;
    }
  }
}

.modal .modal-body.modal-event-details {
  .modal-subtitle {
    color: $primary;
  }
  p {
    color: $gray-dark;
  }
}

.title {
  margin-top: 15px;
  margin-bottom: 0px;
  padding-bottom: 23px;
  color: #7a2ed3;
  font-size: 18px;
  font-weight: bold;
}

.subtitle {
  margin-bottom: 10px;
  color: #7a2ed3;
  font-family: $font-primary;
  font-size: 16px;
  font-weight: bold;

  &-big {
    font-size: 18px !important;
    font-weight: normal;
    color: #8e8e8e !important;
  }

  &-bigger {
    font-size: 18px !important;
    color: #7a2ed3 !important;
    line-height: 25px;

    span {
      font-weight: normal;

      a {
        color: #7a2ed3;
      }
    }
  }
}

.id {
  margin-bottom: 62px;
  color: #b5b5b5;
  font-family: $font-primary;
  font-size: 16px;
  font-weight: bold;
}

.filter {
  margin-bottom: 20px;
  color: #7a2ed3;
  font-size: 18px;
  align-content: space-around;
  display: flex;
  align-items: center;

  i {
    margin-right: 10px;
  }

  .btn {
    font-size: 14px;
    font-weight: bold;
    color: #9a9a9a;
  }
}

.skill {
  margin-top: 92px;

  .add-more {
    font-size: 30px;
    text-align: center;

    i {
      margin-right: 0 !important;
    }
  }
}

.habilidades {
  font-family: $font-primary;
  font-size: 18px;
  font-weight: bold;
  color: #7a2ed3;
  margin-bottom: 0;

  i {
    margin-right: 10px;
  }
}

.btn-action {
  color: #999999;
  display: inline-block;
  font-family: $font-primary;
  font-size: 14px;
  text-align: center;

  &:hover {
    color: $orange;
    text-decoration: none;
  }

  i {
    font-size: 30px;
    display: block;
  }
}

.aprovar-todas {
  text-align: center;
  color: #999999;
  font-family: $font-primary;
  font-size: 14px;

  i {
    font-size: 30px;
    display: block;
  }
}

.border.border-warning {
  border-color: $primary-dark !important;
}

.text-danger {
  color: #7a2ed3 !important;
}

.text-warning {
  color: #f59f8d !important;
}

.class-prefix {
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-weight: bold;
  height: 80px;
  justify-content: center;
  margin: 35px auto 10px;
  padding: 20px 10px;
  width: 80px;

  &-edit {
    background-color: #d4a1dd;
    color: #fff;
    font-size: 70px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  &-variation {
    font-size: 30px;
    height: 70px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 11px 8px 13px;
    text-align: center;
    width: 70px;
  }
}

.badge {
  font-weight: normal;
  padding: 4px 10px;

  &.badge-warning {
    background-color: $orange-dark;
    color: #fff;
  }
}
