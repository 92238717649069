.plansValidationCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #F4F3F3;
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 16px;
  overflow: hidden;

  .planIdLabel {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #FFF;
    padding: 5px;
    font-size: 10px;
    font-weight: bold;
  }

  .planInfo {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    margin-top: 24px;
  }

  .planScheduledInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }

  .col-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .col-1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .iconCard {
    border: none;
    background-color: transparent;
    i {
      font-size: 30px;
      color: #8d8d8d
    }
  }
}

.labelButton {
  color: #8d8d8d;
  font-size: 12px;
}

.updatedTeacher {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}