div.cookiesConfig {
  position: fixed;
  bottom: 71px;
  left: 0;
  max-width: 500px;
  background: #ffffff;

  &.hide {
    display: none;
  }

  div.content {

    div.info {
      padding: 15px;

      div.logo-sm {
        img {
          height: 30px;
        }
      }

      div.text {
        padding: 0 12px;
        margin-top: 5px;

        .cookie-option {
          margin-top: 1rem;
          
          .option {
            display: flex;
            justify-content: space-between;
            padding: 8px 6px;
            border: 1px solid #e1e0e0;
            border-radius: 4px;
            line-height: 22px;

            input.switch + label {
              width: 50%;
              text-align: right;
            }

            input.switch:checked + label {
              color: #5aafd8;
            }

            input.switch:checked + label:before {
              background-color: #5aafd8;
            }

            input.switch.disabled:checked + label {
              color: #e1e0e0;
              cursor: not-allowed;
            }

            input.switch.disabled:checked + label:before {
              background-color: #e1e0e0;
            }

            label {
              margin-bottom: 0;
            }

          }

        }

      }

    }

    div.buttons {
      display: block;
      text-align: center;
      padding: 20px 0 10px 0;

      a {
        display: inline-block;
        background-color: #414141;
        color: #ffffff;
        border-radius: 4px;
        padding: 8px 16px;
        margin: 0 7px 7px 0;
        cursor: pointer;
        width: 190px;
        text-align: center;
      }

      a:hover {
        background-color: #313131;
      }

      a:last-child {
        margin-right: 0;
      }
      
    }

  }

}

input.switch {
  display: none;

  + label {
    position: relative;
    padding-left: 50px;
    user-select: none;
    cursor: pointer;

    &:before {
      content: '';
      transition: background-color 200ms ease;
      background-color: #D3D3D3;
      border-radius: 10px;
      position: absolute;
      height: 20px;
      width: 45px;
      left: 0;
      top: 0;
    }

    &:after {
      content: '';
      transition: left 200ms ease;
      background-color: #fff;
      border-radius: 10px;
      position: absolute;
      height: 16px;
      width: 16px;
      left: 2px;
      top: 2px;
    }
  }

  &:checked + label {
    color: #2CDBA5;

    &:before {
      background-color: #2CDBA5;
    }

    &:after {
      left: 27px;
    }
  }
}
