.optin {
  .close {
    display: none;
  }
  .btn.disabled {
    background-color: #7a2ed3 !important;
  }
  .optin-form {
    margin: 50px 0 20px 0;
  }
  .invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
}

.btn-secondary {
  border: 1px solid #7a2ed3;
  background: #fff;
  width: 100%;
  color: #7a2ed3;
  padding: 14px;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
}
.btn-secondary:hover {
  background: #7a2ed3;
  color: #fff;
}
