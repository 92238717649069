@import '../scss/main';
@import '../scss/main';

.input-group-container{
    position: relative;
    &.is-invalid{
        
        margin-bottom: 40px;
        .invalid-feedback{
            margin-top: -10px;
            display: block;
        }
        i{
            color: $form-feedback-invalid-color;
        }
    }

    &.is-valid{
        .valid-feedback{
            margin-top: -10px;
            display: block;
        }
        i{
            color: $form-feedback-valid-color;
        }
    }
}