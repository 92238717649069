.my-class-button, .my-class-button:hover {
  color: white;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 35px;
  position: absolute;
  right: 8%;
  text-transform: uppercase;
  top: 125px;
}

.my-class-modal-content {
  margin: 0 auto;
  max-width: 600px;
  width: 80%;
}

.my-class-schools-name {
  font-size: 12px;
  padding: 5px 10px;
  margin: 0;
  text-transform: uppercase;
  text-align: left;
}

.Select {
  z-index: 1048;
}

.my-class-float-button {
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.block-button-add {
  // position: absolute;
  // top: -130px;
  // right: -9px;
  // width: 280px;

  .my-class-button {
    position: relative;
    top: 0 ;
    right: 0;
  }
}


/* ADD CLASS MODAL */
.add-class-modal{
  strong {
    font-size: 17px;
  }
  table {
    border: none;
    border-collapse: collapse;
    td {
      padding: 5px;
      font-size: 15px;
    }
    i{
      margin: 5px;
    }
  }
  .table-container {
    padding: 15px;
  }
  .simulated_tooltip {
    position: relative;
    display: inline-block;
  }
  
  .simulated_tooltip .simulated_tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: darkgrey;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    top: -15px;

    position: absolute;
    z-index: 1;
  }

  .simulated_tooltip:hover .simulated_tooltiptext {
    visibility: visible;
  }

  .upload-border {
    border: 2px dashed #7A2ED3;
    border-radius: 3px;
    margin-top: 10px;
    padding: 10px;
  }

  .btn-default {
    color: #7A2ED3;
    min-width: initial;
  }
  
}


/* YEARS TABS */
.year-tabs{
  position: relative;
}

.year-tabs-link{
  cursor: pointer;
  &:hover{
    color: #7A2ED3 !important;
  }
}

.card.card-reports {
  
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    background-color: #f9f9f9;
  }
} 

// Card Menu
.card-menu {
  text-align: center;
  position: absolute;
  color: #E1E0E0;
  font-size: 16px;
  right: 0px;
  top: 5px;
  width: 30px;
  height: 30px;
  padding-top: 4px;
  z-index: 10;
  cursor: pointer;

  &:hover {
      color: #7A2ED3;
      .cm-floating {
          transform: scale(1);
      }
  }

}

.card .cm-floating {
  transition: transform 100ms ease;
  transform-origin: 100% 25%;
  position: absolute;
  transform: scale(0);
  background-color: #F8F8F8;
  border-radius: 3px;
  right: 100%;
  top: 10%;
  z-index: 1;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
  will-change: filter;
  min-width: 100px;

  &:after {
    content: "";
    position: absolute;
    right: -4px;
    top: 4px;
    z-index: -1;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 13px solid #F8F8F8
  }
  
  a {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    font-size: 10px;
    color: #CBCBCB;
    text-decoration: none;
    padding: 6px 8px;
      
      &:hover {
          color: #7A2ED3;
      }
      
      i {
        font-size: 20px;
        margin-right: 8px;
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span {
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        width: 25%;
        margin-right: 4px;
      }

      div {
        width: 75%;
      }
  }
}


.add-newClass {
  .nc-label{
    text-align: left;
  }

  input,
  select,
  textarea {
    width: 100%;
    border: 1px solid #d6d6d6;
    padding: 10px 5px;
    margin-top: 3px;
  }

  textarea {
    padding: 0 5px;
  }
}

a.btn.btn-default {
  border: 2px solid #7A2ED3;
  font-weight: bold;
  text-decoration: none!important;
  color: #7A2ED3 !important;
}

.report-item {
  background: none;
  border: none;
  color: darkgrey;
}
