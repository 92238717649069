.color-switch-popup {
    background: $gray-lighter;
    border-radius: 3px;
    display: none;
    filter: drop-shadow(0 0 5px rgba(black, 0.2));
    left: -35px;
    padding: 20px;
    position: absolute;
    top: 55%;
    z-index: 2;

    &:after {
        background: $gray-lighter;
        content: '';
        height: 20px;
        position: absolute;
        right: -10px;
        top: 40px;
        transform: rotate(45deg);
        width: 20px;
    }

    &.active {
        display: block;
    }

    input {
        display: none;

        + label {
            background-color: $gray-light;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
            display: block;
            height: 30px;
            margin: 10px 5px;
            padding-top: 8px;
            text-align: center;
            width: 30px;

            i {
                display: none;
            }
        }

        &:checked + label i {
            display: block;
        }
    }
}
