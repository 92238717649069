.form-control {
  background-color: $white-ice;
  border: 1px solid #e1e0e0;
  padding: 1rem 0.75rem;
  color: $gray-dark;
  // width: 100% !important;
  height: auto;

  @include media-breakpoint-down(sm) {
    padding: 0.5rem 0.75rem;
  }

  &:focus {
    background-color: $white-ice;
    border-color: $primary;
    box-shadow: none;
  }
}

span.form-control {
  position: relative;

  i {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: $green-ocean;
  }
}

select.form-control {
  height: 53px;
}

.form-control-mob {
  @include media-breakpoint-down(lg) {
    font-size: 0.8rem;
  }
}

.form-group {
  margin: 30px 0 0 0;

  @include media-breakpoint-down(sm) {
    display: block;
    max-width: 100%;
  }

  label {
    font-family: $font-primary;
    color: $gray-dark;
    margin-bottom: 0;
    font-size: 16px;
  }

  &.selected {
    color: $green-ocean;
  }

  .select2 {
    @include media-breakpoint-down(md) {
      width: 100% !important;
    }
  }
}

.form-group-mob {
  @include media-breakpoint-down(sm) {
    label {
      color: $white;
    }

    .form-control {
      color: $white;
      border-right: none;
      border-left: none;
      border-top: none;
      border-bottom: 1px solid $white;
      background-color: transparent;
      padding-left: 0;
    }
  }
}

.toggle-password-warpper {
  position: relative;

  .toggle-password {
    background-color: inherit;
    position: absolute;
    color: $gray-dark;
    cursor: pointer;
    padding: 15px;
    border: none;
    bottom: 0;
    right: 0;
    top: 0;
  }

  .form-control {
    padding-right: 45px;
  }
}

.custom-radio,
.custom-checkbox {
  .custom-control-input {
    ~ label.custom-control-label {
      padding-left: 25px;

      &:before {
        background-color: $white-ice;
        border: 1px solid #e1e0e0;
        left: 0;
      }
    }

    &:checked {
      ~ label.custom-control-label {
        &:before {
          background-color: $white-ice;
          border-color: $primary;
          box-shadow: none;
        }

        &:after {
          color: $orange;
          content: '\f00c';
          font-family: 'Font Awesome 5 Free';
          font-size: 11px;
          font-weight: bold;
          left: 3px;
          top: 4px;
        }
      }
    }
  }
}

.select2 {
  width: 100% !important;
}

.select2-container--default .select2-selection--single {
  border: 1px solid #e1e0e0;
  background: #f8f8f8;
  border-radius: 3px;
  padding: 0.72rem;
  height: auto;
  width: 100% !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 100%;
}

// Adicionar Arquivo
a.upload-file,
button.upload-file {
  background-color: #f8f8f8;
  border: 1px solid #e1e0e0;
  border-radius: 5px;
  text-align: center;
  color: #f78e00;
  line-height: 1em;
  display: block;
  padding: 20px;
  width: 100%;

  i {
    transition: transform 150ms ease;
    margin-bottom: 3px;
    font-size: 40px;
    display: block;
  }

  span {
    font-size: 14px;
  }

  &:hover {
    border: 1px solid #bfbfbf;
    text-decoration: none;

    i {
      transform: scale(0.9);
    }
  }
}

.amount {
  display: inline-block;
  color: $gray-dark;

  @include media-breakpoint-down(sm) {
    float: right;
  }

  .select2-container--default .select2-selection--single {
    padding: 0.02rem;
    height: 30px;
    width: 70px;
  }

  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    right: -25px;
  }
}

// custom radios and checkboxes
input.custom[type="radio"],
input.custom[type="checkbox"] {
  display: none;

  + label,
  + .sub-items > label {
    transition: all 100ms ease;
    margin-top: 15px;
    text-align: left;
    display: inline-block;
    user-select: none;
    color: $gray-dark;
    line-height: 1em;

    &:before {
      content: "";
      transition: all 100ms ease;
      background-image: url('../images/check.svg');
      background-size: 0;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      background-color: $white-ice;
      border: 1px solid #e1e0e0;
      margin-right: 10px;
      vertical-align: middle;
      transform: translateY(-2px);
    }
  }

  &:checked + label,
  &:checked + .sub-items > label {
    color: $primary;

    &:before {
      border-color: $primary;
      background-size: 15px;
    }
  }

  + .sub-items:not(:last-child) {
    border-bottom: 1px solid rgba(#c1c1c1, 0.3);
  }
}

// custom radios and checkboxes
input.custom-text[type="radio"],
input.custom-text[type="checkbox"] {
  display: none;

  + label {
    transition: all 100ms ease;
    margin-top: 0;
    margin-bottom: 15px;
    text-align: left;
    display: inline-block;
    user-select: none;
    padding: 10px;
    border-radius: 4px;
    background-color: $white-ice;
    border: 1px solid #e1e0e0;
    line-height: 1em;
  }

  &:checked + label {
    background-color: $primary-lightest;
    border-color: $primary;
    color: $primary;
  }
}

.group-checkboxes {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  max-height: 0;
  transition: max-height 300ms ease-in-out;
  padding-left: 35px;

  &.show {
    max-height: 60px;
  }
}

.input-group {
  .input-group-prepend {
    .input-group-text {
      background-color: $white-ice;
      padding-left: 20px;
      padding-right: 20px;
      color: $primary-dark;
      font-size: 20px;
    }
  }
}

.was-validated
.form-control:valid,
.form-control.is-valid {
  border: 1px solid #e1e0e0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Cpath d='M7.769,1.49a7.465,7.465,0,0,0-7.5,7.5,7.465,7.465,0,0,0,7.5,7.5,7.465,7.465,0,0,0,7.5-7.5,7.465,7.465,0,0,0-7.5-7.5Zm0,14.4a6.657,6.657,0,0,1-2.694-.546,7.427,7.427,0,0,1-2.2-1.461,7.427,7.427,0,0,1-1.461-2.2A6.657,6.657,0,0,1,.868,8.99,6.657,6.657,0,0,1,1.414,6.3,7.427,7.427,0,0,1,2.875,4.1a7.427,7.427,0,0,1,2.2-1.461,6.657,6.657,0,0,1,2.694-.546,6.657,6.657,0,0,1,2.694.546,7.427,7.427,0,0,1,2.2,1.461,7.427,7.427,0,0,1,1.461,2.2,6.657,6.657,0,0,1,.546,2.694,6.657,6.657,0,0,1-.546,2.694,7.427,7.427,0,0,1-1.461,2.2,7.427,7.427,0,0,1-2.2,1.461A6.657,6.657,0,0,1,7.769,15.892Zm3.768-9.577L6.713,11l-1.69-1.69a.457.457,0,0,0-.335-.141.352.352,0,0,0-.3.141.352.352,0,0,0-.141.3.457.457,0,0,0,.141.335L6.4,11.948a.165.165,0,0,0,.123.088.989.989,0,0,0,.194.018.816.816,0,0,0,.158-.018.165.165,0,0,0,.123-.088l5.141-5a.352.352,0,0,0,.141-.3.542.542,0,0,0-.106-.335.457.457,0,0,0-.335-.141A.352.352,0,0,0,11.537,6.314Z' transform='translate(-0.269 -1.49)' fill='%230dd698'/%3E%3C/svg%3E");
}

textarea.was-validated
textarea.form-control:valid,
textarea.form-control.is-valid {
  background-position: calc(100% - 10px) calc(100% - 10px);
}

.form-search {
  position: relative;

  > i.ti-search {
    color: $orange;
    font-size: 25px;
    margin-top: 2px;
    pointer-events: none;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}