@import '../scss/main';

.creatable-select__control{
    background-color: #F7F7F7 !important;
    border: 1px solid #e1e0e0 !important;
    color: #8D8D8D !important;
    height: auto;
}
.form-group.is-readonly{
    .creatable-select__control{
        pointer-events: none;
        touch-action: none;
    }
}
.creatable-select__input{
    padding-left: 15px;
}
.creatable-select__placeholder{
    margin-left: 15px !important;
}
.creatable-select__control--is-focused{
    background-color: #F7F7F7 !important;
    border: 1px solid $primary !important;
    box-shadow: none !important;
}
.creatable-select__control--is-disabled{
    background-color: rgb(233, 236, 239) !important;
}

.creatable-select__value-container{
    padding: 0 !important;
    height: 53px;
}
.creatable-select__indicator-separator{
    background: none !important;
}
.creatable-select__menu{
    z-index: 99999 !important;
    background: white;
}
.hide-indicator {
    .creatable-select__indicators{
        display: none;
    } 
}
.is-valid {
    .creatable-select__control{
        border-color: $green !important;
    } 
}
.is-invalid{
    .creatable-select__control{
        border-color: $red !important;
    } 
}
.creatable-select__single-value{
    color: grey !important; 
    margin-left: 15px !important;
}