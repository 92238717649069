.cp-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    text-transform: uppercase 
  }

  .text-purple {
    color: #7A2ED3
  }

  .btn {
    border-radius: 200px;
  }
}

.m-r-10 {
  margin-right: 10px;
}

.viewPlan  .updatedTeacher{
  justify-content: flex-start;
  gap: 10px;
}

.btns-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &.disabled {
      background-color: #979797;
      color: #b3b3b3;
      cursor: default;
    }

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.modal-content textarea {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  resize: none;
}

.iconExcel {
  width: 25px;
  margin-left: 5px;
  margin-bottom: 2px;
}