.add-extra-lesson-form { 
  .input-group-container {
    // text-align: left;

    .form-control-label {
      font-size: 16px;
      // color: #7a2ed3;
      margin-bottom: 3px;
      font-weight: 500;
    }
  }

  .fullTimePeriodToggle {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 10px 0;

    .slider:before {
      transform:translateX(-51px);
    }
    
    input:checked + .slider:before {
      transform:translateX(9px);
    }

  }

  .checkbox-list {
    label {
      width: 100%;
      text-align: center;
    }
  }
}

.is-invalid {
  .checkbox-list {
    border-color: #dc3545;
    color: #dc3545;
    label {
      color: #dc3545;
    }
  }
}