.form-login{
    min-height: calc(100vh - 80px);
}
.forgotPassword{
    margin-top: 20px;
    font-size: 12px;
    text-align: center;
}
@media (max-width: 767.98px) {
    .forgotPassword{
        color:#fff;
        font-size: 16px;
    }
}