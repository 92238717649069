.modal {
  &.fade {
    .modal-dialog {
      transform: scale(0.95);
    }
  }

  &.show {
    .modal-dialog {
      transform: scale(1);
    }
  }

  .modal-dialog {
    max-width: 600px;

    &.modal-sm {
      max-width: 370px;
    }
  }

  .modal-content {
    box-shadow: $shadow-default;
    border: none;
  }

  .modal-header {
    border: none;
  }

  .modal-body {
    padding: 0 2rem 2rem;
    text-align: center;

    i.icon {
      font-size: 70px;
      color: #7a2ed3;

      &-small {
        font-size: 40px;
        color: #7a2ed3;
      }
    }

    .modal-title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 25px;
      color: $primary;
    }

    .modal-subtitle {
      font-size: 18px;
      font-weight: bold;
      color: #9d9d9d;
      margin-top: 20px;
    }

    .label {
      font-size: 18px;
    }

    p {
      color: #8e8e8e;
      margin: 20px 0;
    }

    table.table {
      color: #8e8e8e;
    }
  }

  .modal-footer {
    padding: 0;
  }

  a.modal-button {
    border-right: 1px solid #e9ecef;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    text-align: center;
    line-height: 1.2em;
    padding: 0 25px;
    font-size: 18px;
    color: #999;
    z-index: 9999;
    display: flex;
    height: 80px;
    flex: 1;

    @include media-breakpoint-down(sm) {
      padding: 0 15px;
    }

    &.modal-button:hover {
      color: $primary;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.modal-backdrop {
  background: #5e646b;

  &.show {
    opacity: 0.6;
  }
}

.modal-color {
  color: #7a2ed3 !important;
}

.reply-cards {
  padding-top: 60px;

  &-unitary {
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;
    padding-left: 6px;
    border-radius: 6px;
    cursor: pointer;
    transition: all ease 200ms;

    .delete {
      color: #fff;
      font-size: 25px;
      line-height: 35px;
      border-radius: 0 6px 6px 0;
      transition: all ease 250ms;
    }

    &:hover {
      background-color: #f7f7f7;

      .delete {
        color: #969eaa;
        background-color: #e1e1e1;
      }
    }
  }

  &-archive {
    font-size: 14px;
    margin-left: 8px;
    margin-right: 12px;
    color: #969eaa;
  }
}
