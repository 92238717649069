.share-settings {
    background-color: #F8F8F8;
    border-radius: 3px;
    bottom: 35px;
    color: #999999;
    filter: drop-shadow(0 0 20px rgba(black, 0.2)) drop-shadow(1px 1px 5px rgba(black, 0.05));
    font-size: 12px;
    font-weight: normal;
    max-width: 280px;
    padding: 20px;
    position: absolute;
    right: -46px;
    text-align: left;
    width: 100vw;
    z-index: 9999;

    &:after {
        background-color: #F8F8F8;
        bottom: -10px;
        content: '';
        height: 20px;
        position: absolute;
        right: 50px;
        transform: rotate(45deg);
        width: 20px;
    }

    h2 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0;
    }

    .email {
        color: #D0D0D0;
    }

    ul {
        list-style: none;
        margin: 20px 0 0;
        padding: 0;
        
        li {
            border-top: 1px solid #E1E0E0;
            padding: 7px 0;
        }
    }
}
