.change-avatar {
  position: relative;
  width: 100%;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .ca-content {
    border-radius: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    width: 100%;
  }

  .ca-button {
    background-color: rgba(black, 0.5);
    font-family: $font-secondary;
    transform: translateZ(0);
    position: absolute;
    text-align: center;
    font-weight: bold;
    line-height: 0.6;
    font-size: 15px;
    display: block;
    color: white;
    bottom: 0;
    right: 0;
    top: 60%;
    left: 0;
    margin: 0;
    cursor: pointer;
    width: 50%;
    border: none;
    padding: 25px 0 50px;

    i.pe-7s-camera,
    i.ti-paint-bucket {
      @include transition;
      font-size: 25px;
      display: block;
    }

    &:hover {
      text-decoration: none;

      i.pe-7s-camera,
      i.ti-paint-bucket {
        transform: scale(0.9);
      }
    }

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }

    &-left {
      right: 50%;
      padding-left: 30px;
    }

    &-right {
      left: 50%;
      padding-right: 30px;
    }
  }

  input[type="file"] {
    display: none;
  }
}

.avatar-profile {
  background-color: #fff;
  border-radius: 50px;
  text-align: center;
  position: relative;
  height: 40px;
  margin: auto;
  width: 40px;
  border: 2px solid #1EBCDF;

  @include media-breakpoint-down(md) {
    height: 70px;
    width: 70px;
  }

  .avatar-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .avatar-country {
    text-transform: uppercase;
    background-color: #40FFC4;
    border-radius: 100px;
    position: absolute;
    font-weight: bold;
    color: #fff;
    // font-size: 14px;
    font-size: 0;
    padding: 2px 0;
    height: 9px;
    width: 9px;
    right: 0px;
    bottom: 0px;
    border: 2px solid #1EBCDF;

    // @include media-breakpoint-down(md) {
    //   height: 15px;
    //   width: 15px;
    //   border: 2px solid #000;
    // }
  }
}

.avatar {
  background-color: $gray-light;
  display: inline-block;
  border-radius: 50px;
  text-align: center;
  position: relative;
  color: #ffffff;
  font-size: 35px;
  height: 70px;
  width: 70px;

  &:before {
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    left: 50%;
    top: 50%;
  }

  &.avatar-sm {
    height: 60px;
    width: 60px;
  }
}
