.change-avatar{
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  width: 220px; 
  height: 220px;
  border: 2px solid #7A2ED3;
  .ca-button{
      width: 100%;
  }
}
.photo-btn-close{
  background-color: #7A2ED3!important;
  position: absolute;
  color: #fff!important;
  font-size: 28px;
  z-index: 1;
  cursor: pointer;
  border-radius: 30px;
  border: 2px solid #7A2ED3;
  left: 37px;
  top: 12px;
  padding: 0px;
}

.card-password {
  padding: 25px 25px 10px 25px;
  margin-top: 20px;
  position: absolute; 
  z-index: 2;
  display: none;
}

.input-password:hover ~ .card-password {
  display: block;
}

.card2 {
  transition: all .2s ease-in-out;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.137);
  border: none;
  margin-bottom: 30px;
  background-color: white;
}

.input-password:hover ~ .card-password {
  display: block;
}

.pass-icon {
  position: absolute;
  top: 42px;
  right: 20px;
  cursor: pointer;
}

.buttonTab {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0 25px 0 0;
  height: fit-content;
}
.buttonTab * {
  color: rgb(196, 196, 196);
}

.buttonTab.active * {
  color: #7A2ED3;
}