.school-form-row-vacation{ 
  &:hover{
      .school-form-add-vacation{
          opacity: 1;
      }
  }
  .school-form-add-vacation{
      opacity: 0;
      transition: opacity ease 300ms;
      width: 50px;
      left: -60px;
      position: absolute;
      display: flex;
      align-items: center;
      height: 106px;
  }
}
.school-form-vacation{
  position: relative;
  button{
      position:  absolute;
      right: 0;
      top: -17px;
  }
}