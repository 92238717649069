.card-password {
  padding: 25px 25px 10px 25px;
  margin-top: 20px;
  position: absolute; 
  z-index: 2;
  display: none;
}

.input-password:hover ~ .card-password {
  display: block;
}

.pass-icon {
  position: absolute;
  top: 42px;
  right: 20px;
  cursor: pointer;
}