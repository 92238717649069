$scale-red: #7a2ed3;
$scale-orange: #ffaf2e;
$scale-lemon: #b2dd51;
$scale-ocean: #59e6aa;
$scale-green: #0dd698;

.scale {
  .scale-step {
    border-radius: 6px;
    color: #fff;
    position: relative;
    width: 100%;

    &.step-none {
      background-color: #b5b5b5;
    }

    &.step-notAnswered {
      background-color: #ffaf2e;
    }

    &.step-canceled {
      background-color: #555555;
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    .content {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: space-around;
      position: absolute;
      width: 100%;
    }
  }

  .small {
    font-size: 70%;
  }

  &.scale-large {
    .scale-step {
      font-size: 18px;

      .content > div {
        font-size: 16px;
      }
    }

    .percentage {
      font-weight: bold;
      font-size: 40px;
    }
  }

  &.scale-medium {
    display: inline-block;
    margin: auto;

    .scale-step {
      width: 40px;
      font-size: inherit;
    }
  }

  &.scale-label {
    display: flex;
    margin-bottom: 5px;
    margin-top: 5px;

    .scale-step {
      border-radius: 3px;
      height: 20px;
      margin-left: 5px;
      margin-right: 5px;
      width: 20px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @for $i from 0 through 100 {
    @if $i <= 25 {
      .step-#{$i} {
        background-color: mix($scale-orange, $scale-red, $i * 4);
      }
    } @else if $i > 25 and $i <= 50 {
      .step-#{$i} {
        background-color: mix($scale-lemon, $scale-orange, ($i * 4) - 100);
      }
    } @else if $i > 50 and $i <= 75 {
      .step-#{$i} {
        background-color: mix($scale-ocean, $scale-lemon, ($i * 4) - 200);
      }
    } @else {
      .step-#{$i} {
        background-color: mix($scale-green, $scale-ocean, ($i * 4) - 300);
      }
    }
  }
}
