.title-container {
  width: 100%;
  border-bottom: 1px dashed #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding-bottom: 10px;

  .lesson-date, .course-name {
    background-color: #7A2ED3;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    border-radius: 5px;
    padding: 10px 15px;
    margin-right: 15px;
  }
  span {
    font-size: 22px;
  }
}

.lesson-content {
  font-size: 16px;
  margin-bottom: 10px;

  span {
    font-weight: 600;
  }

  .d-flex {
    div {
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }

  .component-icon {
    font-size: 54px;
    margin-right: 10px;
  }
}

.title-configure {
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.resources-container {

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh - 330px);
  height: 100%;
  // padding-bottom: 44px;
  

  .resources-block {
    display: flex;
    justify-content: flex-start;
    gap: 10px;

    .card-resource {
      background-color: #F7F7F7;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 17px 20px 10px 20px;
      border-radius: 5px;
      text-transform: uppercase;
      font-size: 12px;
      width: 100%;
      cursor: pointer;
      text-align: center;
      position: relative;
      border: none;
      color: #8d8d8d;


      .onlineClass-badge,
      .uploadFile-badge,
      .simplifica-badge,
      .lessonFiles-badge {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #7A2ED3;
        color: #fff;
        font-size: 10px;
        padding: 2px 5px;
        border-radius: 0px 3px 0px 0px;
        text-transform: uppercase;
        font-weight: bold;
      }

      &:hover {
        background-color: #7A2ED3;
        color: #fff;

        .onlineClass-badge,
        .uploadFile-badge,
        .simplifica-badge,
        .lessonFiles-badge {
          background-color: #fff;
          color: #7A2ED3;
        }

        svg, path {
          fill: #fff;
        }

        &.no-fill {
          svg, path {
            fill: none;
            stroke: #fff;
          }
        }
      }

      svg {
        margin-bottom: 20px;
        height: 40px;
      }

      &:nth-child(2) {
        margin: 0 6px;
      }
    }
    
  }

  .modal .modal-dialog {
    max-width: 80%;
  }

  .modal-button {
    &.primary {
      &.disabled {
        cursor: not-allowed;

        &:hover {
          color: #999;
        }
      }
    }
  } 
}

.configureButtons {
  display: flex;
  justify-content: space-between;

  .btn {
    font-size: 12px;
    min-width: inherit;
    width: 100%;
    padding: 10px;

    &:nth-child(2) {
      margin: 0 5px;
    }
  }

  .lighter-purple {
    background-color: #B59BF6;
  }
  .light-purple {
    background-color: #924CE3;
  }
}


.simulated_tooltip {
  position: relative;
  display: inline-block;
}

.simulated_tooltip .simulated_tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: darkgrey;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  top: -15px;

  position: absolute;
  z-index: 1;
}

.simulated_tooltip:hover .simulated_tooltiptext {
  visibility: visible;
}

.cs-search__clear-icon {
  color: #7A2ED3!important;
}

.upload-background {
  background-color: #F6EFFF;
  margin-top: 10px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;

  &.disabled {
    background-color: #F7F7F7;
    color: #777;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding: 20px;
    margin: auto
  }

  .subtitle-bigger {
    color: #7A2ED3!important;
  }

  .upload-archive{

    
    table {
      border: none;
      border-collapse: collapse;
      td {
        padding: 5px;
        font-size: 15px;
      }

    }
    .table-container {
      padding: 15px;
    }
  }

  svg {
    margin: 5px;
    font-size: 50px;
    
    path {
      fill: #7A2ED3;
    }

  }

}

.ql-editor {
  min-height: 140px;
}

.block-label {

  align-items: center;
  
  .label {
    color: #fff;
    padding: 5px 8px;
    border-radius: 10px;
    margin: 0 10px 10px 0;
    background-color: #924CE3;

    &:hover {
      background-color: #7A2ED3;
    }
  }
}

.onlineClass-icons,
.uploadFile-icons,
.simplifica-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #666666;

    &:first-child {
      margin-right: 30px;
    }
    &:last-child {
      margin-left: 30px;
    }

    img {
      height: 60px;
      margin-bottom: 15px;
    }
  }
}

.onlineClass-input,
.uploadFile-input,
.simplifica-input {
  margin-top: 50px;
  flex-direction: column;

  input {
    margin-top: 10px;
    border: 1px solid #8d8d8d;
    padding: 10px 15px;
    border-radius: 5px;

    &.danger {
      border-color: #F44336;
    }
  }
}

.btn-planning {
  align-self: stretch;
  max-width: 200px;
}

.lessonBtnGroup {
  gap: 5px;
  width: 100%;
}

.btn-proposal {
  width: calc(25% - 4px)!important;
}

.switch input { 
  display:none;
}
.switch {
  display: inline-block;
  width: 50px;
  height: 20px;
  margin: 0;
  // transform: translateY(50%);
  position: relative;
}
/* Style Wired */
.slider {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  border-radius:30px;
  box-shadow:0 0 0 2px #a9a9a9, 0 0 4px #a9a9a9;
  cursor:pointer;
  border:4px solid transparent;
  overflow:hidden;
   transition:.4s;
}
.slider:before {
  position:absolute;
  content:"";
  width:100%;
  height:100%;
  background:#a9a9a9;
  border-radius:30px;
  transform:translateX(-30px);
  transition:.4s;
}

input:checked + .slider:before {
  transform:translateX(30px);
  background:#7A2ED3;
}
input:checked + .slider {
  box-shadow:0 0 0 2px #7A2ED3,0 0 2px #7A2ED3;
}


.studentToggle {
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  div {
    margin-bottom: 0;
  }
}

.cs-main-container,
.cs-main-container * {
  font-family: Lato, sans-serif!important;
}

.cs-search,
.cs-search__input,
.cs-message-input__content-editor,
.cs-message-input__content-editor-wrapper {
  background-color: #eeeeee!important;
  color: #8d8d8d!important;

  &::placeholder,
  .cs-message-input__content-editor[data-placeholder]:empty:before {
    color: #8d8d8d!important;
  }
}
.cs-message-list__scroll-wrapper {
  padding: 10px!important;
}
.cs-message--incoming .cs-message__content,
.cs-message--outgoing .cs-message__content {border-radius: 10px!important;}

.ps__thumb-y,
.cs-message--incoming .cs-message__content {
  background-color: #B59BF6!important;
  color: #fff!important;

  &::placeholder,
  .cs-message-input__content-editor[data-placeholder]:empty:before {
    color: #fff!important;
  }
}
.cs-search__search-icon {
  color: #8d8d8d!important;
}
.cs-button--attachment,
.cs-button--send,
.cs-typing-indicator__text {
  color: #7A2ED3!important;
}
.cs-typing-indicator__dot {
  background-color: #7A2ED3!important;
}
.cs-conversation__unread-dot {
  background: #7A2ED3!important;
}
.cs-message-separator,
.cs-conversation-header__content .cs-conversation-header__user-name,
.cs-conversation__name,
.cs-conversation__info {
  color: #8d8d8d!important;
}
.cs-message-separator::before, .cs-message-separator::after {
  background-color: #eeeeee!important;
}
.cs-conversation.cs-conversation--active {
  & .cs-conversation__name
  {
    color: #7A2ED3!important;
    font-weight: bold!important;
  }
    min-height: 70px!important;
    background-color: #eeeeee!important;
}
.cs-main-container .cs-conversation-header {

  min-height: 70px!important;
}
.cs-conversation-header,
.cs-conversation-header__content .cs-conversation-header__user-name,
.cs-conversation-header__content .cs-conversation-header__info,
.cs-conversation-header {
  background-color: #eeeeee!important;
}
.cs-message--outgoing .cs-message__content {background-color: #7A2ED3!important;
  color: #fff!important;
}

.cs-conversation-header {
  padding: 1.24em .9em!important;
}

.cs-message--incoming .cs-message__sender-name,
.cs-message--incoming .cs-message__sent-time,
.cs-message--outgoing .cs-message__sender-name,
.cs-message--outgoing .cs-message__sent-time {
  display: block!important;
}
.cs-message:not(:only-child) {
  margin-bottom: 1em!important;
}
.cs-message__avatar {
  justify-content: flex-start!important;
  margin-top: 0px!important;
}
.cs-conversation {
  cursor: inherit!important;
}
.cs-conversation:not(.cs-conversation.cs-conversation--active):hover {
  background-color: #fff!important;
} 
.cs-main-container>.cs-sidebar.cs-sidebar--left {
  flex-basis: 40%!important;
}
.cs-main-container .cs-chat-container {
  flex-basis: 60%!important;
}
.cs-conversation {
  align-items: center!important;
  padding: 0.79em 0.5em!important;
}
.cs-conversation--active {
  border-bottom: solid 1px #d1dbe3!important;
}
.cs-conversation>.cs-avatar {
  width: 30px!important;
  height: 30px!important;
  margin-right: 0.5em!important;
}
.cs-conversation__content {
  margin-right: 0!important;
}
.cs-avatar.cs-avatar--md {
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
}
.cs-conversation__name {
  font-size: 0.9em!important;
}
.cs-conversation__info {
  font-size: 0.7em!important;
}
.cs-conversation__last-sender {
  font-weight: bold!important;
  color: #7A2ED3!important;
}
.cs-conversation--active .cs-conversation__info {
  color: transparent!important;
}



.linkBlock {
  background-color: #7A2ED3;
  border-radius: 10px;
  width: 100%;
  margin: auto;
  padding: 20px;
  color: white;
  margin-top: 20px;
  text-align: left;

  &.inactive {
    background-color: #F7F7F7;
    color: #666666;
  }

  a, a:hover, a:visited, a:active{
    text-decoration: underline;
    color: white;
  }
}

.lessonFilesTable {
  width: 100%;

  thead th {
    padding: 4px 12px;
    border: 1px solid #fff;
  }

  tbody td {
    padding: 4px 12px;

    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;

    &:last-child {
      border-right: 1px solid #fff;
    }

    .userPhoto {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .avatar-profile {
        margin: 0;

        .avatar-sm {
          width: 36px;
          height: 36px;
        }
      }

      .userName {
        margin-left: 10px;
      }
    }
  }
}

.actions {
  text-align: center;
  a {
    cursor: pointer
  }
}

.media-container {
  margin: 50px auto 20px;
  text-align: center;
  position: relative;

  video, img {
    width: 100%;
  }

  .close-btn {
    cursor: pointer;
    border: none;
    border-radius: 100px;
    background-color: white;
    color: #7A2ED3;
    width: 25px;
    height: 25px;
    position: absolute;
    top: -32px;
    right: 0;
  }
}
