@import '../scss/main.scss';

.navbar-toogle-menu{
    margin-left: 10px;
}
/*
@include media-breakpoint-down(sm) {
    .navbar-toogle-menu{
        display: none;
    }
}

.navbar .user-dropdown .ud-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.navbar {
    .hamburguer {
        color: white;
       
    }
    .fa-bars {
        font-size: 20px;
    }
}*/

// .logs-not-read {
//   background-color: #aa190e !important;
//   cursor: pointer;
// }
