.default-table {
    width: 100%;
    thead{
        tr{
            th{
                font-weight: bold;
                text-align: center;
                padding: 10px;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-color: gainsboro;
            }
            th:not(:first-child) {
                border-left: 1px solid gainsboro    ; 
            }
        }
    }
    tbody{
        tr{
            td{
                text-align: center;
                padding: 10px;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-color: gainsboro;
            }
            td:not(:first-child) {
                border-left: 1px solid gainsboro    ; 
            }
        }
    }
}
