.pagination {
    color: #D1D1D1;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-weight: bold;

    a {
        color: inherit;
        display: inline-block;
        font-size: 16px;
        padding: 5px 10px;

        &:hover {
            color: $orange;
        }

    }
   
}
