// Years Tabs
.year-tabs {
  position: relative;
}

.nav-tabs {
  border-bottom: 1px solid $gray;
  margin-bottom: 20px;

  .nav-item {
    margin-right: 30px;
  }

  .nav-link {
    border-bottom: 2px solid transparent;
    color: $gray-medium;
    padding: 0.5rem 0;
    font-weight: bold;
    font-size: 17px;
    border: none;
    outline: none;

    &.active {
      border-bottom: 2px solid $primary;
      color: $primary;
      outline: none;
    }

    &:hover {
      color: $primary;
    }
  }
}

.navigation-tabs {
  position: absolute;
  font-size: 13px;
  top: 9px;
  right: 0;

  li {
    a {
      color: lightgray;
    }
    .active {
      color: #7a2ed3 !important;
    }
  }

  a {
    text-decoration: none;
    padding: 0 10px;
    .nt-icon {
      font-weight: bold;
    }
  }
}

.navigation-tabs-mob {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
    font-size: 13px;
    text-align: center;

    a {
      text-decoration: none;
      padding: 0 10px;

      .ti-angle-left {
        color: lightgray;
      }

      .ti-angle-right {
        color: $primary;
      }

      .nt-icon {
        font-weight: bold;
      }
    }
  }
}
