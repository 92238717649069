.register-form {
    @media (max-width: 767.98px) {
        .label {
            color: white !important;
        }
        .form-check-label {
            color: white !important;
        }
    }
}

.form-check-inline .form-group {
  display: inline-flex;
  flex-direction: row-reverse;
  line-height: 14px;
  margin: 0px 0 0 0;

  label{
      margin-left: 6px;
  }
}
.form-control.is-invalid, .form-control.is-valid{
  background-position: 95% calc(.375em + 0.8rem) !important;
}

.form-select-block {
  display: flex;
  flex-direction: column;
}
.form-select-container {
  display: flex;
  gap: 10px;
}

.card-password {
    padding: 25px 25px 10px 25px;
    margin-top: 20px;
    position: absolute; 
    z-index: 2;
    display: none;
  }
  
  .input-password:hover ~ .card-password {
    display: block;
  }
  
  .pass-icon {
    position: absolute;
    top: 35px;
    right: 20px;
    cursor: pointer;
  }
  
  .required-pass-card  {
    
    .relative {
      position: relative;
      margin: 5px 0;
      padding: 5px;
    }
  
    ul{ 
      list-style-type: none;
    }
  
    .checked {
      background-color: #dfebdf;
      color: #008000;
    }
  
    i {
      font-size: 10px;
      position: absolute;
      left: -15px;
      top: 13px;
  
      &.ti-close {
        color: red;
      }
    
      &.ti-check {
        color: #008000;
      }
    }
  } 

.school-add-modal-form-container {
  .form-group {
      text-align: left !important;
  }
}

.school-add-modal-form-details {
    padding: 15px;
    border: 1px solid #e1e0e0;
    background: white;
    border-radius: 0.25rem;
    margin: 30px 0 0 0;
}


.school-add-modal-form-icon {
    cursor: pointer;
    margin: 10px
}