.myPlanScheduledCourseCard {
  font-size: 16px;
  background-color: #FFF;
  padding: 35px 15px 25px;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;

  .planScheduledIdLabel {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #F4F3F3;
    padding: 5px;
    font-size: 10px;
    font-weight: bold;
  }

  .col {
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    justify-content: center;
    align-items: start;
  }

  .col-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .col-1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .iconCard {
    border: none;
    background-color: transparent;
    i {
      font-size: 30px;
      color: #8d8d8d
    }
  }
}

.horizontal-column.col {
  flex-direction: row!important;
  align-items: center!important;
  justify-content: flex-start!important;
  flex-wrap: wrap!important;

  .lessonsTooltip {
    margin-left: -15px;
    margin-top: -5px;
  }

  .avatar-sm {
    border: 1px solid #7a2edd;
  }
}

.comments {
  transform: scale(2); 
  margin-top: 10px;
  cursor: pointer;

  &.approved {
    color: #4CAF50;
  }

  &.rejected {
    color: #F44336;
  }
}