@import '../../node_modules/bootstrap/scss/bootstrap';

.status-badge {
  border-radius: 8px;
  padding: 3px;
  color: white;
  text-align: center;
}
.status-badge--variant-success {
  background-color: $success;
}
.status-badge--variant-info {
  background-color: $info;
}
.status-badge--variant-warning {
  background-color: $yellow;
}
.status-badge--variant-danger {
  background-color: $red;
}
.status-badge--variant-primary {
  background-color: #7a2ed3;
}
