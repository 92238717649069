.planCardHead {
  font-size: 20px;
  background-color: #F4F3F3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 20px;
  width: 100%;
  border: none;

  &.active {

    i {
      transform: rotate(180deg);
    }
  }
  
  i {
    transition: all 0.5s ease-in-out;
    font-size: 40px;
  }
}

.planCardBody {
  font-size: 16px;
  background-color: #F4F3F3;
  padding: 0 20px;
  border-radius: 10px;
  margin-top: 10px;
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  display: none;

  &.removed {
    opacity: 0.6;  

    .labelBlock .label {
      background-color: #cecece;
    }
  }

  &.active {
    max-height: 1000px;
    display: block;
  }

  .row {
    padding: 15px 0;
  }

  .configBlock {

    .configData {
      font-weight: normal;

      p {
        margin-bottom: 0;
      }

      span {
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    // font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;

    & > div:first-child {
      margin-bottom: 40px;
    }

    
  }

  .labelBlock {
    display: flex;
    justify-content: start;
    align-items: center;
    font-weight: normal;
    flex-wrap: wrap;

    .label {
      color: #fff;
      padding: 5px 8px;
      border-radius: 10px;
      margin: 10px 10px 10px 0;
      background-color: #924CE3;

      // &:nth-child(odd) {
      //   background-color: #C1D174;
      // }
      &:hover {
        background-color: #7A2ED3;
      }
    }

    button {
      border: none;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 30px;
        font-weight: normal;
      }
    }

  }

  .classesBlock {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    input {
      background-color: transparent;
      border: 1px solid #686868;
      width: 80%;
    }
  }

  .group-buttons {
    
    position: absolute;
    top: 10px;
    right: 15px;
    color: #000;
    font-size: 13px;

    .buttons {
      background-color: transparent;
      border: none;
  
      i {
        font-size: 30px;
      }
    }
  }

  
}




// Card Menu
.card-menu {
  text-align: center;
  position: absolute;
  color: #E1E0E0;
  font-size: 16px;
  right: 0px;
  top: 5px;
  width: 30px;
  height: 30px;
  padding-top: 4px;
  z-index: 10;
  cursor: pointer;

  &:hover {
      color: #7A2ED3;
      .cm-floating {
          transform: scale(1);
      }
  }

}

.cm-floating {
  transition: transform 100ms ease;
  transform-origin: 100% 25%;
  position: absolute;
  transform: scale(0);
  background-color: #F8F8F8;
  border-radius: 3px;
  right: 100%;
  top: 10%;
  z-index: 1;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
  will-change: filter;
  min-width: 210px;

  &:after {
    content: "";
    position: absolute;
    right: -9px;
    top: 1px;
    z-index: -1;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 13px solid #F8F8F8
  }
  
  a {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    font-size: 18px;
    color: #CBCBCB;
    text-decoration: none;
    padding: 6px 8px;
      
      &:hover {
          color: #7A2ED3;
      }
      
      i {
        font-size: 20px;
        margin-right: 8px;
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span {
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        width: 25%;
        margin-right: 4px;
      }

      div {
        width: 75%;
      }
  }
}

.form-group {
  text-align: left;

  input,
  select,
  textarea {
    width: 100%;
    border: 1px solid #d6d6d6;
    background-color: white;
    padding: 10px 5px;
    margin-top: 3px;
  }
}


.add-newClass {
  .nc-label{
    text-align: left;
  }

  input,
  select,
  textarea {
    width: 100%;
    border: 1px solid #d6d6d6;
    padding: 10px 5px;
    margin-top: 3px;
  }

  textarea {
    padding: 0 5px;
  }
}

.btn-createClassPeriod {
  display: none;
  font-size: 20px;
  padding: 25px;
  color: #8d8d8d;
  cursor: pointer;

  &.active {

    display: flex;
    justify-content: center;
    align-items: center;
  }

  i {
    margin-right: 5px;
    margin-top: 2px;
  }

  &:hover {
    background-color: #7A2ED3;
    text-decoration: none;
    color: #fff;
  }
}

.sortableHelper, 
.modal .modal-body {
  z-index: 10000;
}
.css-2b097c-container {
  text-align: left!important;
}
