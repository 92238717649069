.btn-back {
  color: #9A9A9A;
  background-color: transparent;
  border: none;
  text-transform: lowercase;
  margin-right: 15px;

  &:hover {
    color: #4f4f4f;
  }
}

.select-class {
  .form-group {
    min-width: 180px;
  }
}

.class-grid {
  font-family: "Exo 2", sans-serif;
  font-weight: bold;
  
  .grid-label {
    font-size: 15px;
    color: #7A2ED3;
    text-transform: lowercase;
    text-align: right;
  }
  .column-label {
    font-size: 28px;
    color: #9A9A9A;
    border-bottom: 1px solid #e1e0e0;
    text-align: center;
    &.disabled {
      color: #E1E0E0;
    }
  }
  .row-label {
    text-align: right;
    font-size: 28px;
  }
  .square {
    width: 45px;
    height: 45px;
    background-color: #E1E0E0;
    border-radius: 5px;
    cursor: pointer;

    &.active {
      background-color: #7A2ED3;
    }
    &.disabled {
      cursor: not-allowed;
      background-color: #F7F7F7;
    }
  }
  .grid-gap {
    gap: 5px;
  }

  .add-row {
    background: transparent;
    border: none;
    padding: 0;
    bottom: 0.7vw;
    right: 11vw;
  }
}
