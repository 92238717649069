@import '../scss/variables';

.icon-button-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.icon-button {
    background-color: transparent;
    //color: gainsboro;
    text-align: center;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        color: rgb(141, 141, 141);
    }
}