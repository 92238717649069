.initials {
    background-color: $gray-medium;
    border-radius: 65px;
    color: $gray-light;
    font-size: 30px;
    font-weight: bold;
    height: 65px;
    margin: auto;
    padding: 10px 0;
    position: relative;
    text-align: center;
    width: 65px;

    &.initials-sm {
        font-size: 15px;
        height: 26px;
        padding: 1px 0;
        width: 26px;
    }

    &.initials-md {
        font-size: 18px;
        height: 32px;
        padding: 1px 0;
        width: 32px;
    }

    &.inline {
        display: inline-block;
        margin: 3px;
    }
}
