@import '../scss/main';

.modal-button {
  cursor: pointer;
  &.primary{
      font-weight: bold;
      color: $primary;
  }
  &.disabled{
    opacity: .5;
  }
}