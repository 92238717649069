.modal{
  background-color: rgba(0,0,0,0.3);
  // overflow: auto !important;
  // display: block !important;
  // // pointer-events: none;
  &.fade {
    transition: opacity ease 300ms;
  }
  &.show{ 
    // pointer-events: all;
    display: block;
    padding-right: 12px;
    opacity: 1;
    z-index: 1051;
    overflow-y: auto;
  }
  &.hidden{
    opacity: 0;
  }
}

.modal-message{
  color: #999 !important;
}
.modal-body.fullscreen{
  max-height: 70vh;
  overflow-y: auto;
}
.modal-lg {
  max-width: 90% !important;
}
.modal-md {
  max-width: 75% !important;
}
.modal-half {
  max-width: 50% !important;
}

.modal.show.modal-dialog {
  z-index: 2;
}
