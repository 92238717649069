.my-class-container {
  position: relative;
}

.my-class-students-container {
  .id {
    font-size: 12px;
    color: #7A2ED3;
    text-align: right;
    margin-bottom: 0;
  }
}
.my-class-button-student, .my-class-button-student:hover {
  color: white!important;
  cursor: pointer;
  font-weight: bold!important;
  padding: 10px 35px!important;
  text-transform: uppercase!important;
  display: flex;
  align-items: center;
}

/*.my-class-actions {
  position: absolute;
  top: 0;
  right: 15px;
}*/

.my-class-error{
  color: red;
}

.my-class-modal-content {
  margin: 0 auto;
  max-width: 600px;
  width: 80%;
}

.Select {
  z-index: 1048;
}

.float-info-wrapper {
  font-size: 12px;
  text-align: right;
}


.float-info-icon {
  cursor: pointer;
  margin-top: -5px;
}

.card-relatorio-aluno {
  margin-bottom: 10px!important;
}

.card .cm-floating {
  transition: transform 100ms ease;
  transform-origin: 100% 25%;
  position: absolute;
  transform: scale(0);
  background-color: #F8F8F8;
  border-radius: 3px;
  right: 100%;
  top: 10%;
  z-index: 1;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
  will-change: filter;
  min-width: 100px;

  &:after {
    content: "";
    position: absolute;
    right: -4px;
    top: 4px;
    z-index: -1;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 13px solid #F8F8F8
  }
  
  a {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    font-size: 10px;
    color: #CBCBCB;
    text-decoration: none;
    padding: 6px 8px;
      
      &:hover {
          color: #7A2ED3;
      }
      
      i {
        font-size: 20px;
        margin-right: 8px;
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span {
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        width: 25%;
        margin-right: 4px;
      }

      div {
        width: 75%;
      }
  }
}