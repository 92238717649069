@import '../scss/main.scss';

.view-mode-selector {
    a {
        border: 1px solid transparent;
        padding: 2px 1px 1px 2px;
        display: inline-block;
        color: #e1e0e0;
        text-align: center;
        border-radius: 3px;
        margin-left: 13px;
        font-size: 22px;
        height: 35px;
        width: 35px;
        &.active, &:hover{
            border-color: $primary;
            text-decoration: none;
        }
    }
}