.title-container .cp-title {
  margin-right: 1vw;
  margin-bottom: 0;
}

.lesson-progressBar {
  width: 12vw;
  // border: 1px solid #ccc;
  background-color: #eeeeee;
  height: 30px;

  // .lesson-progressBar__scheduled,
  // .lesson-progressBar__notApplied {
  //   background-color: #eeeeee;
  //   height: 100%;
  // }
  .lesson-progressBar__applied {
    background-color: #B59BF6;
    height: 100%;
  }
}

.lessons-container {
  width: 100%;

  .progressBar-label {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    //media query medium screen
    @media (max-width: 1024px) {
      justify-content: flex-start;
    }


    .d-flex {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .color-scheduled {
      width: 18px;
      height: 18px;
      background-color: #7A2ED3;
      margin-right: 5px;
    }
    .color-applied {
      width: 18px;
      height: 18px;
      background-color: #B59BF6;
      margin-right: 5px;
    }
    .color-notApplied {
      width: 18px;
      height: 18px;
      background-color: #eeeeee;
      margin-right: 5px;
    }
  }
}

.lessons-container:not(:first-child)  {
 
  .progressBar-label {
    display: none;
  }
}


.lesson-card {
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 12px;
  // cursor: pointer;
  position: relative;

  background-color: #eeeeee;
  color: #9A9A9A;

  // &:hover {
  //   color: #9A9A9A;
  //   text-decoration: none;
  //   box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2), 1px 1px 2px 0 rgba(0, 0, 0, 0.075);
  //   transition: all 0.2s ease-in-out;
  // }

  &:last-child {
    margin-bottom: 50px;
  }

  .lc-title {
    width: 40%;
  }

  // &.scheduled {
  //   background-color: #7A2ED3;
  //   color: #fff;
  // }

  &.applied {
    background-color: #B59BF6;
    color: #fff;
  }

  &.notApplied {
    background-color: #eeeeee;
    color: #9A9A9A;
  }

  .font-14 {
    font-size: 14px;
    // margin: 0 20px;
  }


  .block-label {
    // width: 300px;
    
    .label {
      color: #fff;
      padding: 5px 8px;
      border-radius: 10px;
      margin: 10px 10px 10px 0;
      background-color: #924CE3;
  
      &:hover {
        background-color: #7A2ED3;
      }
    }
  }

  
}


.dates-block {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
  flex-wrap: wrap;

  button {
    position: relative;
    width: 100%;
    min-width: 110px;
    font-size: 13px;
    font-weight: bold;
    background-color: #F7F7F7;
    color: #9A9A9A;
    border-radius: 5px;
    display: flex;
    align-items: center;
    // justify-content: center;
    justify-content: flex-start;
    padding: 8px;
    cursor: pointer;
    border: none;
    z-index: 2;

    &.active, &:hover {
      background-color: #7A2ED3;
      color: #fff;
    }

    i.ti-comments-smiley {
      position: absolute;
      right: 3px;
      top: 3px;
    }
  }
}
// .areaToclick {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   z-index: 1;
// }

.lesson-card-config {
  position: absolute;
  width: 39px;
  height: 39px;
  top: 3px;
  right: 3px;
  text-align: center;
  z-index: 1;
  .icon {
    font-size: 39px;
    cursor: pointer;

    &:hover {
      color: #7A2ED3;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
    }
  }
}

.tippy-tooltip-content {
  color: #8d8d8d;
}
.tippy-popper .tippy-tooltip.light-theme[data-animatefill] {
  background-color: white;
}

.lessonDaysCard {
  position: relative;
  z-index: 0;

  &:hover {
    z-index: 1;
  }

  .card-menu {
    position: absolute;
    top: 2px;
    right: -5px;

    &:hover {
      color: #8d8d8d;
    }
  }
  .cm-floating {
    left: 100%;
    right: inherit;
    min-width: 140px;
    z-index: 100;
    a {
      font-size: 14px;
    }
  }
 

  .cm-floating:after {
    right: inherit;
    left: -9px;
    border-left: none;
    border-right: 13px solid #F8F8F8;
  }
}

.progress-label {
  font-size: 12px;
  position: absolute;
  top: -15px;
  text-transform: lowercase;
}
.progress-percent {
  font-size: 14px;
  padding: 4px 7px;
  mix-blend-mode: multiply;
  position: absolute;
}


.btn-proposal.hasChatMessages {
  background-color: #4c4cff;
  color: white;
}