.form-wrapper {
  min-height: 100vh;
  margin-left: 0;
  width: 50%;

  @include media-breakpoint-down(sm) {
    margin-left: -15px;
    margin-bottom: 0;
    margin-top: 20px;
    min-height: auto;
    padding: 15px;
    width: auto;
  }

  .nav-tabs {
    border-bottom: 1px solid $gray-light;

    @include media-breakpoint-down(sm) {
      border-bottom: 1px solid $primary;
      justify-content: space-around;
      margin-bottom: 30px;
    }

    .nav-item {
      margin-right: 120px;
      margin-bottom: -3px;

      @include media-breakpoint-down(sm) {
        text-align: center;
        margin-right: 0;
        margin-left: 0;
      }

      @include media-breakpoint-down(md) {
        text-align: center;
        margin-right: 60px;
      }

      .nav-link {
        font-family: $font-primary;
        font-weight: 500;
        font-size: 18px;
        padding-left: 0;
        color: $gray;

        @include media-breakpoint-down(sm) {
          color: $primary;
          font-size: 20px;
        }

        &.active,
        &:hover {
          border-bottom: 3px solid $primary;
          color: $primary;

          @include media-breakpoint-down(sm) {
            background-color: transparent;
            color: $primary;
            border-bottom: 2px solid $primary;
            padding-bottom: 5px;
          }
        }
      }
    }
  }

  form {
    padding: 75px 100px;
    max-width: 800px;

    @include media-breakpoint-down(lg) {
      padding: 75px 50px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }

    @include media-breakpoint-only(xl) {
      max-width: 100%;
    }

    .btn {
      margin-top: 40px;
    }
  }

  p {
    margin-top: 30px;
    font-family: $font-primary;
    color: $gray-dark;
  }

  a {
    font-weight: bold;
    color: inherit;
  }

  .label {
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .terms {
    font-family: $font-primary;
    text-align: center;
    color: $gray-dark;
    margin: 5px 0 0 0;
    font-size: 14px;

    @include media-breakpoint-down(sm) {
      color: $white;
      margin: 5px 0 10px 0;
    }
  }
}

.cover-wrapper {
  background-image: url("../images/signup-cover.png");
  background-position: center;
  background-size: cover;
  text-align: center;
  position: fixed;
  z-index: -1;
  bottom: 0;
  left: 50%;
  right: 0;
  top: 0;

  @include media-breakpoint-down(sm) {
    left: 0;
  }

  &:after {
    content: "";
    background-color: rgba($primary, 0.9);
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }

  .brand {
    transform: translateX(-50%);
    position: absolute;
    top: 240px;
    left: 50%;

    @include media-breakpoint-down(sm) {
      top: 50px;
      width: 100%;
    }
  }

  img {
    width: 230px;

    @include media-breakpoint-down(sm) {
      width: 50px;
    }
  }

  .cover-title {
    font-family: $font-secondary;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 30px;
    font-size: 26px;
    color: $white;
    white-space: nowrap;
  }

  a.sm-brand {
    position: absolute;
    transform: translateX(-50%);
    bottom: 100px;
    left: 50%;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    img {
      width: 118px;
      height: auto;
    }
  }
}

.brand-mob {
  display: none;

  @include media-breakpoint-down(sm) {
    display: block;
    text-align: center;

    img {
      width: 50px;
      margin-top: 30px;
    }

    .cover-title {
      font-family: $font-secondary;
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 15px;
      font-size: 17px;
      color: $white;
      margin-bottom: 0;
    }
  }
}

a.sm-brand-mob {
  display: none;

  @include media-breakpoint-down(sm) {
    display: block;
    width: 100%;

    img {
      display: block;
      width: 50px;
      height: auto;
      margin: 40px auto;
    }
  }
}
