.label-primary {
  background-color: #2CDBA5;
  color: #fff;
  border-radius: 20px;
  padding: 5px 10px;
}

.label-secondary {
  background-color: #2c86db;
  color: #fff;
  border-radius: 20px;
  padding: 5px 10px;
}

.form-group.form-group-mob.mt-0.mr-3 {
  width: 20%;
}

.form-group.form-group-mob.mt-0.mr-3.w-100 {
  width: 100%;
}