.switch {
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;

    input {
        display: none;

        +label {
            background-color: #CFD5E6;
            border-radius: 18px;
            cursor: pointer;
            height: 16px;
            position: relative;
            transition: background-color 300ms ease;
            vertical-align: middle;
            width: 36px;
            will-change: background-color;

            &:after {
                background-color: #FAFBFE;
                border-radius: 14px;
                box-shadow: $shadow-hover;
                content: '';
                height: 16px;
                left: 0;
                position: absolute;
                top: 0;
                transition: left 300ms ease;
                width: 16px;
                will-change: left;
            }
        }

        &:checked + label {
            background-color: #0DD698;

            &:after {
                left: 20px;
            }
        }
    }
}
